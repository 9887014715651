import copy from 'copy-to-clipboard'
import { isIOS as detectIsIos, isMacOs as detectIsMacOs } from 'react-device-detect'
import { PlatformDetailEnum } from '@/constants/platform-detail.enum'

export const enableScroll = (enable: boolean) => {
  const body = document.body

  if (enable) {
    body.style.removeProperty('overflow')
    body.style.removeProperty('width')
  } else {
    body.style.cssText = `
      overflow: hidden;
      width: 100%;
    `
  }
}

export function isProduction(): boolean {
  return process.env.FITPETMALL_ENV === 'production'
}

export const isServerSide = () => typeof window === 'undefined'

const isClient = () => typeof window !== 'undefined'

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0

const PlatformPattern = {
  RN_iOS: /fitpetApp_ios/,
  RN_Android: /fitpetApp_android/,
  iOS: /fitpetIosApp/,
  Android: /fitpetAndroidApp/,
  AndroidWeb: /Android/,
  iOSWeb: /iPhone/,
  MobileWeb: /Mobile|mobile/,
}

export const getPlatform = (userAgent: string = navigator?.userAgent) => {
  if (PlatformPattern.RN_iOS.test(userAgent)) {
    return PlatformDetailEnum.RnIos
  }
  if (PlatformPattern.RN_Android.test(userAgent)) {
    return PlatformDetailEnum.RnAndroid
  }
  if (PlatformPattern.iOS.test(userAgent)) {
    return PlatformDetailEnum.IosApp
  }
  if (PlatformPattern.Android.test(userAgent)) {
    return PlatformDetailEnum.AndroidApp
  }
  if (PlatformPattern.AndroidWeb.test(userAgent)) {
    return PlatformDetailEnum.AndroidWeb
  }
  if (PlatformPattern.iOSWeb.test(userAgent)) {
    return PlatformDetailEnum.IosWeb
  }
  if (!PlatformPattern.MobileWeb.test(userAgent)) {
    return PlatformDetailEnum.PcWeb
  }
  // PC 구분 어떻게?
  return PlatformDetailEnum.Etc
}

export const isInApp = () => isClient() && window?.isInApp && window.isInApp()
export const isInAndroid = () => isClient() && [PlatformDetailEnum.AndroidApp].includes(getPlatform())
export const isInIos = () => isClient() && [PlatformDetailEnum.IosApp].includes(getPlatform())
const isAndroidWeb = () => isClient() && [PlatformDetailEnum.AndroidWeb].includes(getPlatform())
export const isMobileWeb = () => isAndroidWeb() || isIOSWeb()
export const isIOSWeb = () => isClient() && [PlatformDetailEnum.IosWeb].includes(getPlatform())
export const isInRnAndroid = () => isClient() && [PlatformDetailEnum.RnAndroid].includes(getPlatform())
export const isIOS = () => detectIsIos
export const isMacOs = () => detectIsMacOs

let orderReturnFormImages: File[] | undefined
export const setOrderReturnFormImages = (files: File[] | undefined) => {
  orderReturnFormImages = files
}

export const getOrderReturnFormImages = () => {
  return orderReturnFormImages
}

// base를 대상으로 target이 부분집합인지 감별
export const isSubset = (base: any[], target: any[]) => {
  const uniqueItemsBase = [...new Set(base)]
  const uniqueItemsTarget = [...new Set(target)]
  return uniqueItemsTarget.every((item) => uniqueItemsBase.includes(item))
}

// base 와 target 이 교집이 있는지 감별
export const isInterSection = (base: any[], target: any[]) => {
  const uniqueItemsBase = [...new Set(base)]
  const uniqueItemsTarget = [...new Set(target)]
  return uniqueItemsTarget.some((item) => uniqueItemsBase.includes(item))
}

// 클립보드 copy
export const copy2clipboard = (text: string) => {
  copy(text)
}

type Emptiness = null | undefined | string | any[] | Record<string, any>

// 값이 비어있는지 확인
export const isEmpty = <T extends Emptiness>(value: T): boolean => {
  if (value == null) {
    return true
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        return false
      }
    }
    return true
  }

  return false
}

// Object 타입인지 확인
export const isObject = (value: unknown): value is Record<string, unknown> => {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export const endsWith = (str: string, search: string, position?: number): boolean => {
  if (position === undefined || position > str.length) {
    position = str.length
  }
  const end = position
  const start = end - search.length
  return end >= start && str.slice(start, end) === search
}

export const toArray = <T>(value: T | T[]): T[] => {
  if (value == null) {
    return []
  }
  if (Array.isArray(value)) {
    return value
  }
  if (typeof value === 'string') {
    return value.split('') as unknown as T[]
  }
  if (value instanceof Set || value instanceof Map) {
    return Array.from(value.values()) as T[]
  }
  if (typeof value === 'object' && Symbol.iterator in value) {
    return Array.from(value as Iterable<T>)
  }
  return [value]
}

type DebouncedFunction<F extends (...args: any[]) => void> = {
  (...args: Parameters<F>): void
  cancel: () => void
}

export const debounce = <F extends (...args: any[]) => void>(fn: F, delay: number): DebouncedFunction<F> => {
  let timeoutId: NodeJS.Timeout | null = null

  const debouncedFn = ((...args: Parameters<F>): void => {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }) as DebouncedFunction<F>

  debouncedFn.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  return debouncedFn
}

export const throttle = <F extends (...args: any[]) => void>(fn: F, wait: number): F => {
  let lastCall = 0
  let timeoutId: NodeJS.Timeout | null = null

  return ((...args: Parameters<F>) => {
    const now = Date.now()
    const remaining = wait - (now - lastCall)
    if (remaining <= 0) {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
      lastCall = now
      fn(...args)
    } else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        lastCall = Date.now()
        timeoutId = null
        fn(...args)
      }, remaining)
    }
  }) as F
}

export const uniqWith = <T>(arr: T[], comparator: (a: T, b: T) => boolean): T[] => {
  return arr.reduce<T[]>((acc, item) => {
    if (!acc.some((existingItem) => comparator(existingItem, item))) {
      acc.push(item)
    }
    return acc
  }, [])
}

export const isEqual = <T>(a: T, b: T): boolean => {
  console.log('isEqual')
  if (a === b) return true
  if (typeof a !== typeof b || a == null || b == null) return false
  if (typeof a === 'object') {
    const keysA = Object.keys(a) as (keyof T)[]
    const keysB = Object.keys(b) as (keyof T)[]
    if (keysA.length !== keysB.length) return false
    return keysA.every((key) => isEqual(a[key], b[key]))
  }
  return false
}

export const cloneDeep = <T>(obj: T): T => {
  console.log('cloneDeep')
  if (obj === null || typeof obj !== 'object') return obj
  if (Array.isArray(obj)) return obj.map(cloneDeep) as T
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, cloneDeep(v)])) as T
}

export const get = <T, K extends keyof T>(obj: T, path: K | K[], defaultValue?: any): any => {
  const keys = Array.isArray(path) ? path : [path]
  let result: any = obj
  for (const key of keys) {
    result = result?.[key]
    if (result === undefined) return defaultValue
  }
  return result
}

export const set = <T>(obj: T, path: string | string[], value: any): T => {
  const keys = Array.isArray(path) ? path : path.split('.')
  let current: any = obj

  keys.slice(0, -1).forEach((key, index) => {
    if (!current[key]) {
      current[key] = typeof keys[index + 1] === 'number' ? [] : {}
    }
    current = current[key]
  })

  current[keys[keys.length - 1]] = value
  return obj
}

export const unset = <T>(obj: T, path: string | string[]): boolean => {
  const keys = Array.isArray(path) ? path : path.split('.')
  let current: any = obj

  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) return false
    current = current[keys[i]]
  }

  return delete current[keys[keys.length - 1]]
}

export const compact = <T>(arr: (T | null | undefined | false | 0 | '')[]): T[] => {
  return arr.filter(Boolean) as T[]
}

export const round = (number: number, precision: number = 0): number => {
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export const floor = (number: number, precision: number = 0): number => {
  const factor = Math.pow(10, precision)
  return Math.floor(number * factor) / factor
}

type DeepMerge<T, U> = {
  [K in keyof T | keyof U]: K extends keyof U
    ? K extends keyof T
      ? DeepMerge<T[K], U[K]>
      : U[K]
    : K extends keyof T
    ? T[K]
    : never
}
export const merge = <T, U>(target: T, source: U): DeepMerge<T, U> => {
  const output = { ...target } as any

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      // 직접 호출
      const targetValue = (target as any)[key]
      const sourceValue = (source as any)[key]

      if (isObject(targetValue) && isObject(sourceValue)) {
        output[key] = merge(targetValue, sourceValue)
      } else {
        output[key] = sourceValue
      }
    }
  }

  return output
}

export const flatten = <T>(arr: T[][]): T[] => arr.reduce((acc, val) => acc.concat(val), [])

export const find = <T>(arr: T[], predicate: (value: T) => boolean): T | undefined => {
  for (const item of arr) {
    if (predicate(item)) return item
  }
  return undefined
}

export const uniqBy = <T>(arr: T[], iteratee: ((item: T) => any) | keyof T): T[] => {
  const seen = new Set<any>()

  return arr.filter((item) => {
    const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee]
    if (seen.has(key)) {
      return false
    } else {
      seen.add(key)
      return true
    }
  })
}

export const isNil = (value: any): value is null | undefined => {
  return value === null || value === undefined
}

export const ceil = (number: number, precision: number = 0): number => {
  const factor = Math.pow(10, precision)
  return Math.ceil(number * factor) / factor
}
