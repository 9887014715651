import { SvgImages } from '@/components/common/icons/svg-images'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { GlobalTheme } from '@/styles/globalTheme'

const { iconSize, color } = GlobalTheme

export type SvgIconType = keyof typeof SvgImages

type FitpetIconProps = {
  type: SvgIconType
  size?: typeof iconSize[keyof typeof iconSize]
  width?: typeof iconSize[keyof typeof iconSize]
  color?: typeof color[keyof typeof color]
}

export const FitpetIcon = ({
  type,
  size = iconSize.size16,
  color = GlobalColorEnum.Gray700,
  width,
}: FitpetIconProps) => {
  const SvgComponent = SvgImages[type]
  return <div />
}
