import React, { forwardRef, createContext, useContext, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  checked?: boolean
  value?: string
  children?: ReactNode
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  gap: 0.8rem;
  width: fit-content;

  &:disabled {
    cursor: not-allowed;
  }
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface CheckboxGroupContextType {
  values: string[]
  onChange: (value: string, checked: boolean) => void
}

const CheckboxGroupContext = createContext<CheckboxGroupContextType | undefined>(undefined)

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, onChange, value, children, disabled, ...props }, ref) => {
    const { color } = useTheme()
    const group = useContext(CheckboxGroupContext)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (group) {
        group.onChange(value as string, event.target.checked)
      } else {
        onChange?.(event)
      }
    }

    const isChecked = group ? group.values.includes(value as string) : checked

    return (
      <CheckboxContainer disabled={disabled}>
        <HiddenCheckbox
          ref={ref}
          checked={isChecked}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          {...props}
        />
        {match({ isChecked: !!isChecked, disabled: !!disabled })
          .with({ isChecked: true, disabled: false }, () => (
            <FitpetIcon type="ic-checkbox-small-checked" color={color.blue500} />
          ))
          .with({ isChecked: true, disabled: true }, () => (
            <FitpetIcon type="ic-checkbox-small-checked" color={color.blue100} />
          ))
          .with({ isChecked: false, disabled: false }, () => <FitpetIcon type="ic-checkbox-small-unchecked" />)
          .with({ isChecked: false, disabled: true }, () => <FitpetIcon type="ic-checkbox-small-unchecked-disabled" />)
          .exhaustive()}
        {children}
      </CheckboxContainer>
    )
  }
)

Checkbox.displayName = 'Checkbox'

interface CheckboxGroupProps {
  children: ReactNode
  values: string[]
  onChange: (values: string[]) => void
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ children, values, onChange }) => {
  const handleChange = (value: string, checked: boolean) => {
    const newValues = checked ? [...values, value] : values.filter((v) => v !== value)
    onChange(newValues)
  }

  return (
    <CheckboxGroupContext.Provider value={{ values, onChange: handleChange }}>{children}</CheckboxGroupContext.Provider>
  )
}
