import { ParsedUrlQuery } from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { match } from 'ts-pattern'
import { createContainer } from 'unstated-next'
import { getGetSearchProductsV3QueryKey, getSearchProductsV3 } from '@/apis/rest/generated/hooks'
import { PetTypeEnum, SearchProductResponseDtoV2 } from '@/apis/rest/generated/types'
import { RelatedSearchedKeywords } from '@/components/domains/layouts/header/searchHeader.type'
import ROUTES from '@/constants/legacy/constRoutes'
import { PAGE_LIMIT } from '@/constants/pages.const'
import { PathParamKeyEnum } from '@/constants/path-param-key.enum'
import { PRODUCTS_SELECT_FILTER_ORDER_BY_V4 } from '@/constants/products-select-filter-orderby.const'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { QueryStringValueEnum } from '@/constants/query-string-value.enum'
import { useCustomRouter, useSearchResult } from '@/containers/hooks'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'
import { useRelatedSearchedKeywordsQuery } from '@/graphql/generated/hooks'
import { getSortDirectionByOrder, parseSortByOptionSelected } from '@/utils/getSortDirectionByOrder'
import { encodeId } from '@/utils/utilApi'
import { localSearchFilterList } from '@/utils/utilLocalStorage'
import { UtilUrlController } from '@/utils/utilUrlController'

export enum ConvertType {
  Brand = 'brand',
  Category = 'category',
  Product = 'product',
}

type SelectFilterListType = {
  category: string[]
  brand: string[]
  minPrice: number
  maxPrice: number
}

const searchResultContext = () => {
  const [searchData, setSearchData] = useState<SearchProductResponseDtoV2>(localSearchFilterList.load())
  const { ref, inView } = useInView()
  const [parentCategory, setParentCategory] = useState<string>('')
  const [selectFilterList, setSelectFilterList] = useState<SelectFilterListType>({
    category: [],
    brand: [],
    minPrice: 0,
    maxPrice: searchData?.maxPrice || 0,
  })
  const [relatedKeywords, setRelatedKeywords] = useState<RelatedSearchedKeywords>([])

  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const { initSearchBodyMount } = useSearchResult()

  const { replace, query, pathname } = useCustomRouter()
  const {
    [QueryStringKeyEnum.SearchPetType]: petTypeQueryParam,
    [QueryStringKeyEnum.SearchQuery]: searchKeywordQueryParam,
    [QueryStringKeyEnum.FilterExcept]: except,
    [QueryStringKeyEnum.OrderBy]: sortBy,
    [QueryStringKeyEnum.FilterCategory]: filterCategory,
    [QueryStringKeyEnum.FilterBrand]: filterBrand,
    [QueryStringKeyEnum.FilterPrice]: filterPrice,
    [PathParamKeyEnum.PetType]: petTypePathParam,
    [PathParamKeyEnum.Keyword]: searchKeywordPathParam,
  } = query as {
    [QueryStringKeyEnum.SearchPetType]?: string
    [QueryStringKeyEnum.SearchQuery]?: string
    [QueryStringKeyEnum.FilterExcept]?: string
    [QueryStringKeyEnum.OrderBy]?: string
    [QueryStringKeyEnum.FilterCategory]?: string
    [QueryStringKeyEnum.FilterBrand]?: string
    [QueryStringKeyEnum.FilterPrice]?: string
    [PathParamKeyEnum.PetType]?: string
    [PathParamKeyEnum.Keyword]?: string
  }

  const convertFromBase64 = (str: string) => {
    return Number(atob(str).split(':')[1])
  }

  const convertToBase64 = (value: number | undefined, type: ConvertType) => {
    return match(type)
      .with(ConvertType.Brand, () => {
        return encodeId('BrandType', value)
      })
      .with(ConvertType.Category, () => {
        return encodeId('CategoryType', value)
      })
      .with(ConvertType.Product, () => {
        return encodeId('ProductType', value)
      })
      .exhaustive()
  }

  const searchKeyword = searchKeywordQueryParam || searchKeywordPathParam || ''
  const petType = UtilUrlController.urlParamToPetType(petTypeQueryParam || petTypePathParam)

  const queryParams = {
    keyword: searchKeyword as string,
    petType: petType as PetTypeEnum,
    includeSoldOut: except !== QueryStringValueEnum.SoldOut,
    sortBy: parseSortByOptionSelected(sortBy || PRODUCTS_SELECT_FILTER_ORDER_BY_V4.RECOMMEND.value),
    sortDirection: getSortDirectionByOrder(sortBy || PRODUCTS_SELECT_FILTER_ORDER_BY_V4.RECOMMEND.value),
    ...(filterCategory &&
      typeof filterCategory === 'string' && {
        categoryIds: filterCategory.split('_').map((i) => convertFromBase64(i)),
      }),
    ...(filterBrand &&
      typeof filterBrand === 'string' && {
        brandIds: filterBrand.split('_').map((i) => convertFromBase64(i)),
      }),
    ...(filterPrice &&
      typeof filterPrice === 'string' && {
        minPrice: Number(filterPrice.split('~')[0]),
        maxPrice: Number(filterPrice.split('~')[1]),
      }),
  }

  /*
  isRealSoldOut true로 보낼경우 isRealSoldOut가 false인 상품만 조회
  graphQL false로 보낼경우 isRealSoldOut가 false인 상품만 조회
   */

  // 검색 조건 변경시 반영안되고 최신데이터 이슈로 캐시 제거
  const {
    data: searchProducts,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
    remove,
  } = useInfiniteQuery(
    getGetSearchProductsV3QueryKey({
      ...queryParams,
    }),
    ({ pageParam = PAGE_LIMIT.DEFAULT_PAGE }) =>
      getSearchProductsV3({
        ...queryParams,
        size: PAGE_LIMIT.LIST,
        page: pageParam,
        sortSoldOutToLast: true,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.products?.length === PAGE_LIMIT.LIST ? allPages.length + PAGE_LIMIT.DEFAULT_PAGE : undefined
      },
      onSuccess: (res) => {
        if (filterCategory || filterBrand || filterPrice) {
          return
        }
        setSearchData(res?.pages[0])
        localSearchFilterList.save(res.pages[0])
      },
    }
  )

  const { loading: relatedSearchKeywordLoading, data: relatedSearchKeyword } = useRelatedSearchedKeywordsQuery({
    variables: {
      keyword: searchKeyword as string,
      petType,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (!data.relatedSearchedKeywords) return
      setRelatedKeywords(data.relatedSearchedKeywords)
    },
  })

  const getCategoryNameById = (categoryIds: string | null): string[] | null => {
    try {
      if (!categoryIds) return null

      const _categoryIds = categoryIds.split('_')
      const categoryFilterList = searchData?.categories

      return (categoryFilterList || []).flatMap((parentCategory) =>
        parentCategory.detailCategories
          .filter((category) => _categoryIds.includes(convertToBase64(category.id, ConvertType.Category)))
          .map((category) => category.name)
      )
    } catch (e) {
      return null
    }
  }

  const getBrandNameById = (brandIds: string | null): string[] | null => {
    try {
      if (!brandIds) return null

      const _brandIds = brandIds.split('_')
      const brandFilterList = searchData?.brands

      return (brandFilterList || [])
        .filter((brand) => _brandIds.includes(convertToBase64(brand?.id, ConvertType.Brand)))
        .map((brand) => brand.name)
    } catch (e) {
      return null
    }
  }

  const appendFilterQuery = (pathname: string, queries: ParsedUrlQuery) => {
    remove()
    replace({
      pathname: pathname,
      query: { ...query, ...queries },
    })
  }

  const headerRefCallback = useCallback(
    (node: HTMLDivElement) => {
      if (!relatedSearchKeyword || !node) return
      setTimeout(() => setHeaderHeight(node.offsetHeight))
    },
    [relatedSearchKeyword]
  )

  const totalCount = searchProducts?.pages[0].totalCount
  const soldOutCount = searchProducts?.pages[0].soldOutCount

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    setParentCategory('')
    setSelectFilterList((prevState) => ({
      ...prevState,
      category: [],
      brand: [],
    }))
  }, [query])

  // 최근검색어 저장 로직
  useEffect(() => {
    if (pathname === ROUTES.SEARCH_RESULT && searchKeyword) {
      initSearchBodyMount()
    }
  }, [pathname, searchKeyword])

  const { getAbByKey } = useAbGroup()

  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.MAX_REWARD_AND_TOTAL_SALES_EXPOSURE_IN_SEARCH_RESULTS,
  })

  return {
    ref,
    isSuccess,
    searchProducts,
    isFetchingNextPage,
    totalCount,
    soldOutCount,
    PAGE_LIMIT,
    refetch,
    isLoading,
    searchData,
    appendFilterQuery,
    getBrandNameById,
    convertToBase64,
    convertFromBase64,
    getCategoryNameById,
    isEmpty: totalCount === 0 && !isLoading,
    selectFilterList,
    parentCategory,
    setParentCategory,
    setSelectFilterList,
    headerRefCallback,
    headerHeight,
    relatedSearchKeywordLoading,
    relatedKeywords,
    petType,
    searchKeyword,
    abCode,
  }
}

export const SearchResultContainer = createContainer(searchResultContext)
