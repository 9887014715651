import { FC, memo } from 'react'
import { ValueOf } from 'type-fest'
import { IconNameEnum } from '@/constants/icon-name.enum'
import FlatIconList from '@/constants/legacy/flatIconList'
import { GlobalTheme } from '@/styles/globalTheme'

const { iconSize, color } = GlobalTheme

/** theme 의 값을 받기 위해 string 추가 */
type FlatIconProps = {
  type: IconNameEnum
  size?: ValueOf<typeof iconSize>
  width?: ValueOf<typeof iconSize>
  color?: ValueOf<typeof color>
}

/**
 * @deprecated
 * '@/components/common/icons/fitpet-icon' 을 사용하세요.
 */
const FlatIcon: FC<FlatIconProps> = ({ type, size = iconSize.size16, color, width }) => {
  // const { SvgComponent, defaultColor } = FlatIconList[type]
  return <div />
}

export default memo(FlatIcon)
