import { FC, PropsWithChildren, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FitpetButton } from '@/components/common/buttons/fitpet-button'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import { UseModalProps } from '@/containers/hooks/useModal'
import { RootState } from '@/stores/store'
import { enableScroll } from '@/utils/utilCommon'
import Modal from './Modal'

export type ModalButtonType = 'CANCEL' | 'ACTION' | 'ACTION_CANCEL' | 'NONE'

type RenderButtonProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  buttonType?: ModalButtonType
  // eslint-disable-next-line react/no-unused-prop-types
  buttonHtmlType?: 'button' | 'reset' | 'submit'
  // eslint-disable-next-line react/no-unused-prop-types
  form?: string
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  onOk?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  onCancel?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  okText?: string
  // eslint-disable-next-line react/no-unused-prop-types
  cancelText?: string
  // eslint-disable-next-line react/no-unused-prop-types
  okButtonDisabled?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  cancelButtonDisabled?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  afterClose?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  noBackBehavior?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  backAction?: boolean
}

export type ModalBaseProps<T> = {
  style?: React.CSSProperties
  useModalProps: UseModalProps<T>
} & RenderButtonProps
const ModalBase: FC<PropsWithChildren<ModalBaseProps<any>>> = ({
  useModalProps,
  onOk,
  onCancel,
  afterClose,
  cancelText,
  okText,
  children,
  form,
  buttonHtmlType,
  loading,
  noBackBehavior = false,
  backAction = true,
  style,
  ...props
}) => {
  const { visible, hideModal } = useModalProps
  const bottomSheetData = useSelector((rootState: RootState) => rootState.reduxDataReducers.bottomSheetData)
  const isAnyVisible = Object.values(bottomSheetData).some((visible) => visible)

  useEffect(() => {
    if (!visible && isAnyVisible) {
      return
    }
    enableScroll(!visible)
  }, [visible])

  const _handleOk = async () => {
    if (form === '' || buttonHtmlType !== 'submit') {
      hideModal()
    }
    if (onOk) {
      onOk()
    }
  }

  const _handleCancel = () => {
    hideModal()
    if (onCancel) {
      onCancel()
    }
  }

  const _handleBackGroundCandle = () => {
    hideModal()
    if (onCancel) {
      onCancel()
    }
  }

  return (
    <>
      <Modal open={visible}>
        <Modal.Content>
          <>{children}</>
        </Modal.Content>
        <Modal.Footer>
          <RenderButtons
            onOk={_handleOk}
            onCancel={_handleCancel}
            okText={okText}
            cancelText={cancelText}
            form={form}
            buttonHtmlType={buttonHtmlType}
            loading={loading}
            {...props}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

const RenderButtons: FC<RenderButtonProps> = ({ buttonType = 'ACTION', ...restProps }) => {
  if (buttonType === 'CANCEL') {
    return (
      <ButtonContainer>
        <NewRow justify="center">
          <RenderCancelButton {...restProps} />
        </NewRow>
      </ButtonContainer>
    )
  }

  if (buttonType === 'ACTION') {
    return (
      <ButtonContainer>
        <NewRow justify="center">
          <RenderActionButton {...restProps} />
        </NewRow>
      </ButtonContainer>
    )
  }
  if (buttonType === 'ACTION_CANCEL') {
    return (
      <ButtonContainer>
        <NewRow justify="center" gap="0.4rem">
          <NewCol flex={1}>
            <RenderCancelButton {...restProps} />
          </NewCol>
          <NewCol flex={1}>
            <RenderActionButton {...restProps} />
          </NewCol>
        </NewRow>
      </ButtonContainer>
    )
  }

  return null
}

const RenderCancelButton: FC<RenderButtonProps> = ({ onCancel, cancelText, cancelButtonDisabled = false }) => {
  return (
    <FitpetButton width="100%" variant="tertiary" onClick={onCancel} disabled={cancelButtonDisabled}>
      <FitpetButton.ButtonText>{cancelText}</FitpetButton.ButtonText>
    </FitpetButton>
  )
}

const RenderActionButton: FC<RenderButtonProps> = ({
  onOk,
  okText,
  form,
  buttonHtmlType,
  okButtonDisabled = false,
}) => {
  return (
    <FitpetButton width="100%" onClick={onOk} disabled={okButtonDisabled} form={form} type={buttonHtmlType}>
      <FitpetButton.ButtonText>{okText}</FitpetButton.ButtonText>
    </FitpetButton>
  )
}

const ButtonContainer = styled.div``

export default ModalBase
