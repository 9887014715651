import { FC } from 'react'
import { Image } from '@/components/common/images/Image'
import { ImageLayoutEnum } from '@/constants/image-layout.enum'
import { ImageObjectFitEnum } from '@/constants/image-object-fit.enum'
import { HEALTH_HOST, S3_HOST_URL, S3_PET_FOLDER } from '@/constants/legacy/constEnv'

type ImageBaseProps = {
  src: string
  alt?: string
  objectFit?: ImageObjectFitEnum | string // TODO: Enum 을 만들었으나 당장 적용하기에는 너무 많아 향후 string 을 지우고 enum 으로 대체 시킬 수 있도록 한다
  layout?: ImageLayoutEnum | string
}
/**
 *
 * @deprecated
 * next/image를 사용하고 있음
 *
 * 새로 만든 Image 컴포넌트 사용하기
 *
 * src/components/common/images/Image.tsx
 */
const ImageBase: FC<ImageBaseProps> = ({
  src,
  alt = '',
  objectFit = ImageObjectFitEnum.Contain,
  layout = ImageLayoutEnum.Fill,
  ...props
}) => {
  const getSrc = (src: string, blurDataURL?: string) => {
    if (!src) {
      return blurDataURL ?? ''
    }
    if (src.startsWith(HEALTH_HOST ?? '') || src.startsWith(S3_PET_FOLDER ?? '')) {
      return src
    }
    return `${S3_HOST_URL}/${src}`
  }
  return <Image src={getSrc(src)} alt={alt} {...props} />
}

export default ImageBase
